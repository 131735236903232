    <!-- Footer START -->
    <footer id="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <div class="footer-logo">
                        <img src="../../../assets/img/logo.png" alt="footer-logo">
                    </div>
                    <div class="footer-text mt-15">
                        <p>MoorsMed est le premier produit de gestion digitalisée des cabinets que <a href="https://www.moors.tech">MoorsTech</a>
 propose aux professionnels de la santé.</p>
                    </div>
                    <ul class="footer-contact">
                        <li><i class="fa fa-phone"></i>+212 656721928/+212 654425094</li>
                        <li><i class="fa fa-envelope"></i>contact@moors.tech</li>
                    </ul>
                </div>
    
                <div class="col-md-3 col-sm-3 col-xs-12 col-sm-offset-1">
                    
                </div>
    
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <h2>Liens utiles</h2>
                    <ul class="footer-list">
                        <li><a href="https://www.moors.tech">MoorsTech</a></li>
                        <li><a href="https://www.lesdigiteurs.cci-paris-idf.fr/digitalisation/actualites/pourquoi-digitaliser-son-entreprise#:~:text=La%20digitalisation%20pour%20améliorer%20les%20processus&text=Chacun%20trouve%20plus%20rapidement%20un,partagé%20instantanément%20grâce%20au%20digital.&text=Le%20tout-numérique%20encourage%20ainsi,notamment%20avec%20un%20travail%20collaboratif.">Pourquoi digitaliser son business</a></li>
                        <li><a href="http://www.exception-management.net/logiciels-medicaux-pour-les-professionnels-de-sante-quels-avantages/#:~:text=Vous%20pourrez%20ainsi%20mieux%20se,organisation%20au%20niveau%20du%20personnel.">Quels sont les avantages des logiciels médicaux ?</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    <div class="footer-bar">
        <span>© Copyright 2021 <a href="https://www.moors.tech">MoorsTech</a>
 - All Rights Reserved</span>
    </div>
    <!-- Footer END -->
    