<div class="section-block-grey">
    <div class="container">
        <section class="grid__Section-sc-18hz4pi-1 MHCfc">
            <div class="mb-10"><p
                    class="typography__Text-j211pc-0 kQddsv">Voulez-vous en savoir plus? Creusons plus profondément</p></div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Critères de base</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Nombre d’utilisateurs</p></td>
                        <td>Jusqu’à 4</td>
                        <td>Jusqu’à 10</td>
                        <td>Dépend</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Visualisation et collaboration</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Limitation des droits de lecture et de modification selon les profils</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Protection des données</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Interfaces</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Version web</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Version mobile</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Services de notification</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">informer les patients des changements de rendez-vous par MAIL</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">informer les patients des changements de rendez-vous par SMS</p></td>
                        <td style="position: relative"><img src="assets/img/checked.svg" alt=""><span style="font-size: 30px; position: absolute; top: 15px">*</span></td>
                        <td style="position: relative"><img src="assets/img/checked.svg" alt=""><span style="font-size: 30px; position: absolute; top: 15px">*</span></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    </tbody>
                </table>
                <span style="font-size: 20px">*</span> Le nombre des sms envoyés par jour est limité


            </div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Aide à la décision</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Tableau de bord</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Propositions d’optimisation des temps d’attente</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Gestion des ressources matérielles</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Inventaire</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Propositions d’optimisation des temps d’attente</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Stockage de document</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Taille de stockage</p></td>
                        <td>100 GB</td>
                        <td>1 TB (1000 GB)</td>
                        <td>Jusqu'à Illimité</td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Vitesse d'accès aux documents</p></td>
                        <td>HOT</td>
                        <td>HOT</td>
                        <td>HOT</td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Protection de la confidentialité</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pricingstyles__TableWrapper-sc-13xa828-2 dcFpuu">
                <table class="pricingstyles__Table-sc-13xa828-3 jsyKEU">
                    <thead>
                    <tr>
                        <th>Support Client</th>
                        <th>Essential<div>499 MAD/Mois</div></th>
                        <th>Premium<div>999 MAD/Mois</div></th>
                        <th>Custom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Guide d’utilisateur</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    <tr>
                        <td><p class="typography__Text-j211pc-0 gWHjPO">Support client à la demande</p></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                        <td><img src="assets/img/checked.svg" alt=""></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>

    <div style="text-align: center; font-size: 20px">Pour plus d'informations, <a style="color: #1b6d85" routerLink="/contact">contactez nous</a></div>
</div>