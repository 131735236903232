import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from './contact.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formData: FormGroup=this.builder.group({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required]),

  });
  constructor(private builder: FormBuilder, private contactService: ContactService,private snackBar: MatSnackBar) { }

  ngOnInit() {
  }


  onSubmit(formData:any) {
    formData.website="moorsmed";
    this.contactService.PostMessage(formData)
      .subscribe((response:any) => {
        //location.href = 'https://mailthis.to/confirm'
        this.snackBar.open("Message bien envoyé, nous vous contacterons bientôt ", "D'accord", {
          duration: 5000,
        });
        this.formData.reset();
      }, error => {
        this.snackBar.open("Une erreur s'est produite, veuillez réessayer plus tard", "D'accord", {
          duration: 5000,
        });
      })
  }

}
