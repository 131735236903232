<!-- Contact START -->
<div class="section-block" id="contact">
	<div class="section-heading center-holder">
		<h2>Contact</h2>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-md-4 col-sm-4 col-xs-12">
				<div class="contact-left-side">
					<div class="contact-left-box">
						<div class="contact-box-full clearfix">
							<div class="contact-icon">
								<i class="fa fa fa-phone"></i>
							</div>
							<div class="contact-info">
								<h5>Téléphone</h5>
								<p>(+212) 520092648 </p>
								<p>(+212) 654425094 </p>
								<p>(+212) 656721928 </p>
							</div>
						</div>


						<div class="contact-box-full clearfix">
							<div class="contact-icon">
								<i class="fa fa-envelope-o"></i>
							</div>
							<div class="contact-info">
								<h5>Email</h5>
								<p>contact@moors.tech</p>
							</div>
						</div>

						<div class="contact-box-full clearfix">
							<div class="contact-icon">
								<i class="fa fa-clock-o"></i>
							</div>
							<div class="contact-info">
								<h5>Horaires</h5>
								<p>jours: Lundi-Samedi </p>
								<p>Lundi 09:00 – 19:00</p>
								<p>Mardi 09:00 – 19:00</p>
								<p>Mercredi 09:00 – 19:00</p>
								<p>Jeudi 09:00 – 19:00</p>
								<p>Vendredi 12:00 – 19:00</p>
								<p>Samedi 09:00 – 13:00</p>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-8 col-sm-8 col-xs-12">
				<div class="contact-right-side">

					<h2>N'hésitez pas à nous contacter!</h2>
					<mat-card-content>
						<form [formGroup]="formData" (ngSubmit)="onSubmit(formData.value)" class="contact-form">
						<div class="row">
							<div class="col-md-6 col-sm-6 col-xs-12">
								<label>Prénom</label>
								<input type="text" name="firstName" formControlName="firstName">
							  </div>

								<div class="col-md-6 col-sm-6 col-xs-12">
									<label>Nom</label>
									<input type="text" name="lastName"  formControlName="lastName">
								  </div>
								<div class="col-md-6 col-sm-6 col-xs-12">
									<label>Email</label>
									<input type="email" name="email"  formControlName="email">
								  </div>
								<div class="col-md-6 col-sm-6 col-xs-12">
									<label>Numéro de téléphone</label>
									<input type="text" name="phone"  formControlName="phone">
								  </div>

								<div class="col-xs-12">
									<label>Votre Message</label>
									<textarea  type="text" name="message"  formControlName="message"></textarea>
								  </div>

							<div class="center-holder">
								<button type="submit"
								class="btn btn-primary"
								[disabled]="!formData.valid">Envoyer le message
								</button>
							</div>
						</div>
					</form></mat-card-content>
					
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Contact END -->