

    
    <!-- Slider START -->
    <div class="pogoSlider" style=" background: #000" id="js-main-slider">
    
        <div class="pogoSlider-slide" data-transition="fade" data-duration="1000"
             style="background-image:url('../../../assets/img/whats.jpg');">
            <div class="container">
                <div class="center-slide-content slider-content ">
                    <div class="row center-holder">
                        <h2 class="pogoSlider-slide-element" data-in="slideLeft" data-out="slideRight" data-duration="800"
                            data-delay="550" style="color: #173059">Solution de transformation digitale simplifiée </h2>
                        <div class="col-md-10 col-sm-12 col-xs-12 col-md-offset-1">
                            <p class="pogoSlider-slide-element hidde" data-in="slideRight" data-out="slideUp"
                               data-duration="850" data-delay="1000" style="color: #173059">Le seul logiciel de gestion de cabinet qui vous permet
                                de travailler de manière entièrement virtuelle où que vous soyez</p>
    <!--                        <a href="#" class="button-lg primary-button mt-30 pogoSlider-slide-element" data-in="slideRight"-->
    <!--                           data-out="slideDown" data-duration="1250" data-delay="600">Read More</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="pogoSlider-slide small-slide" data-transition="slide" data-duration="1000"
             style="background-image:url('../../../assets/img/security.png');">
            <div class="container">
                <div class="center-slide-content slider-content ">
                    <div class="row center-holder">
                        <h2 class="pogoSlider-slide-element" data-in="slideLeft" data-out="slideRight" data-duration="800"
                            data-delay="550" style="color: #1e3965; text-shadow: 0 0 3px #FFFFFF, 0 0 5px #FFFFFF;">Confidentialité des données assurée à 100%</h2>
                        <div class="col-md-10 col-sm-12 col-xs-12 col-md-offset-1">
                            <p class="pogoSlider-slide-element hidde" data-in="slideRight" data-out="slideUp"
                               data-duration="850" data-delay="1000" style="color: #1e3965; text-align: center">Seuls les médecins et personnel autorisé auront accès aux informations personnelles des patients</p>
    <!--                        <a href="#" class="button-lg primary-button mt-30 pogoSlider-slide-element" data-in="slideRight"-->
    <!--                           data-out="slideDown" data-duration="1250" data-delay="600">Read More</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="pogoSlider-slide" data-transition="slide" data-duration="1000"
             style="background-image:url(../../../assets/img/waiting.jpg);">
            <div class="container">
                <div class="center-slide-content slider-content ">
                    <div class="row center-holder">
                        <h2 class="pogoSlider-slide-element" data-in="slideLeft" data-out="slideRight" data-duration="800"
                            data-delay="550" style="color: #1e3965">Améliorez la productivité de votre cabinet ou clinique</h2>
                        <div class="col-md-10 col-sm-12 col-xs-12 col-md-offset-1">
                            <p class="pogoSlider-slide-element hidde" data-in="slideRight" data-out="slideUp"
                               data-duration="850" data-delay="1000" style="color: #1e3965">MOORSMED vous aide à gérer votre cabinet de manière fluide et efficace.</p>
    <!--                        <a href="#" class="button-lg primary-button mt-30 pogoSlider-slide-element" data-in="slideRight"-->
    <!--                           data-out="slideDown" data-duration="1250" data-delay="600">Read More</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    
        <div class="pogoSlider-slide small-slide" data-transition="slide" data-duration="1000"
             style="background-image:url('../../../assets/img/nostress.jpg'); background-position-y: 150px">
            <div class="container">
                <div class="center-slide-content slider-content ">
                    <div class="row center-holder">
                        <h2 class="pogoSlider-slide-element" data-in="slideLeft" data-out="slideRight" data-duration="800"
                            data-delay="550" style="color: #1e3965; text-shadow: 0 0 3px #FFFFFF, 0 0 5px #FFFFFF;">Un personnel motivé, des patients satisfaits.</h2>
                        <div class="col-md-10 col-sm-12 col-xs-12 col-md-offset-1">
                            <p class="pogoSlider-slide-element hidde" data-in="slideRight" data-out="slideUp"
                               data-duration="850" data-delay="1000" style="color: #1e3965; text-align: center">Concentrez-vous moins sur la gestion de votre cabinet et passez plus de temps avec vos clients
    
                            </p>
    <!--                        <a href="#" class="button-lg primary-button mt-30 pogoSlider-slide-element" data-in="slideRight"-->
    <!--                           data-out="slideDown" data-duration="1250" data-delay="600">Read More</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <!-- Slider END -->
    
    
    <!-- Articles START -->
    <div class="section-block" id="features-list">
        <div class="container">
            <div class="section-heading center-holder">
                <h2>Des modules à forte valeur ajoutée pensés pour améliorer votre productivité</h2>
            </div>
            <div class="row items-c">
    
    
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box">
                        <i class="icon-clipboard"></i>
                        <h4 style="font-size: 16px !important">Gestion dossiers patients</h4>
                        <p style="font-size: 13px">Données groupés dans un seul onglet et accessibles durant les visites et contrôles...</p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
    
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box">
                        <i class="icon-calendar"></i>
                        <h4>Planification Smart</h4>
                        <p>Propositions de créneaux vides, annulation, urgence, planification des pauses...</p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
    
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box">
                        <i class="icon-line-chart"></i>
                        <h4>Tableau de bord</h4>
                        <p>Dashboard organisé et affichant différents indicateurs de performance...</p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box">
                        <i class="icon-network2"></i>
                        <h4>Service de messagerie</h4>
                        <p>Envoi des messages et notifications aux patients...<br/><br/></p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box">
                        <i class="icon-document-1"></i>
                        <h4>Facturation et documents</h4>
                        <p> Générez la factures pour impression ou envoi électronique à vos patients...</p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box">
                        <i class="icon-box-1"></i>
                        <h4>Gestion du stock et des services médicaux</h4>
                        <p style="font-size: 13px">Ajoutez, modifiez et supprimez les services médicaux en toute simplicité...</p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box" >
                        <i class="icon-networking-1"></i>
                        <h4>Consultation en temps réel</h4>
                        <p>Lorsqu'une modification est effectuée, tous les utilisateurs vont être notifiés sur place...</p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="article-box">
                        <i class="icon-time"></i>
                        <h4>Optimisation des temps d’attente</h4>
                        <p>Analyse des données en continu pour vous offrir des propositions d'optimisation...</p>
    <!--                    <a href="#">Voir plus <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Articles END -->
    
    <!-- Pricing START -->
    <div class="section-block">
        <div class="container">
            <div class="section-heading center-holder">
                <h2>Pricing</h2>
            </div>
            <div class="row" style="display: flex;flex-wrap: wrap; justify-content: center; gap: 30px; wrap-option: wrap;">
    
                <div style="min-width: 300px; max-width: 300px;">
                    <div class="pricing-list">
                        <div class="pricing-top">
                            <h4>Essentials</h4>
                        </div>
                        <div class="pricing-digits">
                            <span>MAD</span>
                            <h2>499</h2>
                            <p>Par mois</p>
                        </div>
                        <div class="option-list">
                            <ul>
                                <li>Jusqu’à 4 utilisateurs</li>
                                <li>Application web en ligne</li>
                                <li>Planification Smart</li>
                                <li>Dossiers Clients illimités</li>
                                <li>Gestion des stocks digitalisée</li>
                                <li>Base de données des maladies</li>
                                <li>Tableau de Bord</li>
                                <li>Stockage illimité</li>
                                <li>Support Client</li>
                                <li>notifications pour les utilisateurs</li>
                                <li>notifications pour les patients</li>
                            </ul>
                        </div>
                        <div class="pricing-button">
                            <a routerLink="/pricing">Voir plus</a>
                        </div>
                    </div>
                </div>
    
                <div style="min-width: 300px; max-width: 300px;" >
                    <div class="pricing-list">
                        <div class="pricing-premium">
                            <h4>Premium</h4>
                        </div>
                        <div class="pricing-digits premium-digits">
                            <span>MAD</span>
                            <h2>999</h2>
                            <p>Par mois</p>
                        </div>
                        <div class="option-list">
                            <ul>
                                <li>Jusqu’à 10 utilisateurs</li>
                                <li>Application web en ligne</li>
                                <li>Planification Smart</li>
                                <li>Dossiers Clients illimités</li>
                                <li>Gestion des stocks digitalisée</li>
                                <li>Base de données des maladies</li>
                                <li>Tableau de Bord avancé</li>
                                <li>Stockage illimité</li>
                                <li>Support Client</li>
                                <li>Notifications pour les utilisateurs</li>
                                <li style="position: relative">Notification pour les patients<span class="special-service">+</span></li>
                            </ul>
                        </div>
                        <div class="pricing-button-premium">
                            <a routerLink="/pricing">Voir plus</a>
                        </div>
                    </div>
                </div>
    
                <div style="min-width: 300px; max-width: 300px;" >
                    <div class="pricing-list">
                        <div class="pricing-top">
                            <h4>Custom made</h4>
                        </div>
                        <div class="pricing-digits">
                            <span>Selon vos besoins</span>
                            <h2></h2>
                        </div>
                        <div class="option-list">
                            <ul>
                                <li>Tous les services premium sont inclus</li>
                                <li>Contactez-nous pour demander un devis</li>
                            </ul>
                        </div>
                        <div class="pricing-button">
                            <a routerLink="/contact">Contactez-nous</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pricing END -->
    
    
    
    
    <!-- About Section START -->
    <div class="section-block" id="about-us">
        <div class="container">
            <div class="col-md-5 col-sm-6 col-xs-12 mb-15">
                <div class="section-heading-left">
                    <h2>A propos de nous</h2>
                    <div class="text-content" style="text-align: justify">
                        <p>MoorsMed est le premier produit de gestion digitalisée des cabinets que <a href="https://www.moors.tech">MoorsTech</a> propose aux professionnels de la santé. <br/>
    
                            <a href="https://www.moors.tech">MoorsTech</a>; développeur et intégrateur de logiciels, propose des solutions de digitalisation des processus adaptés aux établissements de santé de toute taille (Hôpitaux, cliniques, cabinets…). <br/>
    
                            Nous faisons de la digitalisation un atout pour organiser vos ressources humaines et matérielles en toute aisance et sécurité. Nous proposons des solutions adaptées à vos besoins réels tout en étant flexible à toute mise à niveau future. <br/><br/>
    
                            Notre service unique vous permet de bénéficier de tous les modules dont vous avez besoin pour bien gérer votre activité et sur les différents appareils que vous utilisez (application web, application mobile).</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 col-md-offset-1">
                <img src="https://image.freepik.com/free-vector/health-medical-science-healthcare-background-digital-technology-doctor_36402-400.jpg" alt="about-img" class="rounded-border shadow-primary">
            </div>
        </div>
    </div>
    <!-- About Section END -->
    
    
    
 