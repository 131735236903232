<!-- Projects START -->
<div class="section-block">
	<div class="section-heading center-holder">
		<h2  class="">Foire aux questions</h2>
	</div>

	<div class="container">
		<div class="row">
			<div class="col-md-6 col-sm-12 col-xs-12">
		
				<!-- Accordions START -->
				<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

				  <div class="panel panel-default accordion">
				    <div class="panel-heading accordion-heading" role="tab" id="headingOne">
				      <h4 class="panel-title accordion-title">
				        <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							Comment obtenir la solution MoorsMed ?
				        </a>
				      </h4>
				    </div>
				    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
				      <div class="panel-body accordion-body">
						Après avoir testé gratuitement notre solution pendant 30 jours, vous pouvez choisir d'entrer les informations de votre carte de crédit et serez alors facturé au mois le mois au tarif mensuel du plan que vous avez choisi.
				      </div>
				    </div>
				  </div>

				  <div class="panel panel-default accordion">
				    <div class="panel-heading accordion-heading" role="tab" id="headingTwo">
				      <h4 class="panel-title accordion-title">
				        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
							Quelle est la différence entre MoorsMed Essentials et MoorsMed Premium ?
				        </a>
				      </h4>
				    </div>
				    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
				      <div class="panel-body accordion-body">
						Les deux packs offrent une expérience complète de toutes les fonctionnalités de l’application moorsMed. La différence réside dans la taille du cabinet ou clinique ainsi que de la taille du stockage dont vous avez besoin. Vous pouvez consulter la page de tarification pour plus d’informations.				    </div>
				  </div>

				  <div class="panel panel-default accordion">
				    <div class="panel-heading accordion-heading" role="tab" id="headingThree">
				      <h4 class="panel-title accordion-title">
				        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
							Quelles sont les versions de navigateur prises en charge ?
						</a>
				      </h4>
				    </div>
				    <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
				      <div class="panel-body accordion-body">
						MoorsMed fonctionne sur les différentes versions de Google Chrome, Mozilla Firefox, Edge...etc. et sur les ordinateurs de bureau/portables + vos appareils mobiles.
					</div>
				    </div>
				  </div>

				  

				 				  

				</div>	
				<!-- Accordions END -->						
			</div>


		
		</div>
		<div class="col-md-6 col-sm-12 col-xs-12">

			<!-- Accordions START -->
			<div class="panel-group" id="accordion-2" role="tablist" aria-multiselectable="true">

				<div class="panel panel-default accordion">
					<div class="panel-heading accordion-heading" role="tab" id="heading3">
					  <h4 class="panel-title accordion-title">
						<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="false" aria-controls="collapse3">
							Combien coûte la solution MoorsMed ?
						</a>
					  </h4>
					</div>
					<div id="collapse3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
					  <div class="panel-body accordion-body">
						Nous offrons différents packs adaptés à vos besoins. Le pack MoorsMed Essentials est à 499dh/mois et le pack MoorsMed Premium coûte 999dhs/mois. Vous pouvez consulter la page de tarification pour plus d’informations.				      </div>
					</div>
				  </div>
				<div class="panel panel-default accordion">
					<div class="panel-heading accordion-heading" role="tab" id="heading4">
					  <h4 class="panel-title accordion-title">
						<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse4" aria-expanded="false" aria-controls="collapse4">
							Est-ce que je dois payer séparément pour le service après-vente ?
						</a>
					  </h4>
					</div>
					<div id="collapse4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading4">
					  <div class="panel-body accordion-body">
						Notre priorité est que vous puissiez utiliser MoorsMed pour améliorer la qualité de votre service. Nous offrons le service après-vente à tous nos clients, si vous souhaitez obtenir de l’aide vous pourrez nous contacter par téléphone ou par email pendant les horaires précisés sur le site.				      </div>
					</div>
				  </div>

			  <div class="panel panel-default accordion">
				<div class="panel-heading accordion-heading" role="tab" id="heading5">
				  <h4 class="panel-title accordion-title">
					<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapse5" aria-expanded="false" aria-controls="collapse5">
						Comment m’assurer que les données de mes patients ne seront pas perdues ?
					</a>
				  </h4>
				</div>
				<div id="collapse5" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading5">
				  <div class="panel-body accordion-body">
					Les données sont sauvegardées chaque jour sur le Cloud et peuvent être exportés à tout moment selon votre choix.
				</div>
				</div>
			  </div>			  				  				  
  
			</div>	
			<!-- Accordions END -->						
		</div>	
	</div>
</div>
<!-- Projects END -->