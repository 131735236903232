import { Component, OnInit,OnChanges } from '@angular/core';
import {
  Router,
} from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnChanges {
  public active:string="home";

  constructor(private router:Router) {     
  }

  ngOnInit(): void {
        this.changeActive();
  }
  ngOnChanges(): void {
    this.changeActive();
  }
  changeActive(value?:string){

    if(value){
      this.active=value;
    }
    else {
    this.active=window.location.href.split('/')[3];
    if(this.active==="") this.active="home";
    }

  }

}
