
    <!-- Preloader Start-->
    <div id="preloader">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
    <!-- Preloader End -->
    
    
    <!-- Navbar START -->
    <header>
        <nav style="background-color: rgba(28, 35, 48, 0.85)" class="navbar navbar-default navbar-custom navbar-fixed-top"
             data-offset-top="200">
            <div class="container">
                <div class="row">
                    <div class="navbar-header navbar-header-custom">
                        <button type="button" class="navbar-toggle collapsed menu-icon" data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <a class="navbar-logo" routerLink="/"><img src="../../../assets/img/logo.png"
                                                                      alt="logo"></a>
                    </div>
    
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav navbar-right navbar-links-custom">
                            <li [class.active-link]="active === 'home'"><a (click)="changeActive('home')" routerLink="/">Accueil</a></li>
    <!--                        <li class="dropdown">-->
    <!--                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"-->
    <!--                               aria-expanded="false">Fonctionnalités</a>-->
    <!--                            <ul class="dropdown-menu dropdown-menu-left">-->
    <!--                                <li><a href="services-grid.html">Services Grid</a></li>-->
    <!--                                <li><a href="services-detail-1.html">Services Detail 1</a></li>-->
    <!--                                <li><a href="services-detail-2.html">Services Detail 2</a></li>-->
    <!--                            </ul>-->
    <!--                        </li>-->
                            <li ><a routerLink="/" id="features-button" (click)="changeActive('home')" >Fonctionnalités</a></li>
                            <li  [class.active-link]="active === 'pricing'" (click)="changeActive('pricing')" ><a routerLink="/pricing">Pricing</a></li>
                            <li  [class.active-link]="active === 'contact'" (click)="changeActive('contact')" ><a routerLink="/contact">Contact</a></li>
                            <li><a routerLink="/" id="about-us-button" (click)="changeActive('home')" >à propos</a></li>
                                <li  [class.active-link]="active === 'faq'" ><a routerLink="/faq" (click)="changeActive('faq')" >FAQ</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </header>
    <!-- Navbar END -->
    