import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor() {
          this.loadScript();
   }

  ngOnInit(): void {
  }
  public loadScript() {
    let link = document.createElement('link');
  link.href = 'assets/css/pricing-styles.css'; // put there your js file location
  link.rel="stylesheet"
    link.type="text/css"
 document.getElementsByTagName('head')[0].appendChild(link);

  }

}
