import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() {
          this.loadScript();
   }

  ngOnInit(): void {
  }
  public loadScript() {
    let link = document.createElement('link');
  link.href = 'assets/css/pogo-slider.min.css'; // put there your js file location
  link.rel="stylesheet"
 document.getElementsByTagName('head')[0].appendChild(link);
  let link2 = document.createElement('link');
  link2.href = 'assets/css/slider.css'; // put there your js file location
  link2.rel="stylesheet"
 document.getElementsByTagName('head')[0].appendChild(link2);
  let node = document.createElement('script');
  node.src = 'assets/js/jquery.pogo-slider.min.js'; // put there your js file location
  node.async = true;
 document.getElementsByTagName('head')[0].appendChild(node);
 let node2 = document.createElement('script');
  node2.src = 'assets/js/pogo-main.js'; // put there your js file location
  node2.async = true;
 document.getElementsByTagName('head')[0].appendChild(node2);
  }

}
